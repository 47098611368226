import { api, jsonApiRequest, LINKED_MODULES_BASE } from '@/shared/api';
import { serializeModule } from './transform';

export const getModules = async () => api.request(`/web_api/v1/challenge_modules`);

export const getModulesByUser = async (challengerId) =>
  api.request(`/web_api/v1/users/${challengerId}/challenge_modules_completed_by_user`);

export const createModule = ({ module, challengeId }) =>
  jsonApiRequest({
    data: serializeModule(module, challengeId),
    options: { method: 'POST' },
    url: LINKED_MODULES_BASE,
  });

export const updateModule = ({ module, challengeId }) =>
  jsonApiRequest({
    data: serializeModule(module, challengeId),
    options: { method: 'PATCH' },
    url: `${LINKED_MODULES_BASE}/${module.id}`,
  });

export const deleteModule = (moduleId) =>
  jsonApiRequest({
    options: { method: 'DELETE' },
    url: `${LINKED_MODULES_BASE}/${moduleId}`,
  });
