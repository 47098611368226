import React, { useCallback } from 'react';
import { Alert } from 'react-bootstrap';

export function ServerAlertError({ errors, errorKey, clearErrors }) {
  const onAlertClose = useCallback(() => {
    clearErrors(errorKey);
  }, [clearErrors, errorKey]);

  const error = errors[errorKey];

  return (
    <Alert dismissible onClose={onAlertClose} show={Boolean(error)} variant="danger">
      <Alert.Heading>Error!</Alert.Heading>
      <div>{error?.message}</div>
    </Alert>
  );
}
