import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { requiredMessage } from '@/shared/ErrorMessage';
import { Select } from '@/admin/challenges/modules/form/Select';
import { useSelectOptions } from '@/admin/challenges/modules/form/useSelectRender';
import { getUserOption } from '@/admin/challenges/modules/user/utils';
import { useUsers } from '@/admin/challenges/modules/user/useUsers';
import { linkedModulesName } from '@/admin/challenges/modules/module/const';
import { challengerName } from './const';

export const Challengers = ({ isDisabled }) => {
  const {
    formState: { errors },
    control,
    resetField,
    setValue,
  } = useFormContext();

  const { data: { list: users = [] } = {}, isFetching } = useUsers();

  const onChallengerChange = useCallback(
    (value) => {
      setValue(challengerName, value);
      resetField(linkedModulesName);
    },
    [resetField, setValue],
  );

  return (
    <Select
      control={control}
      errors={errors}
      label="Challenger"
      name={challengerName}
      onChange={onChallengerChange}
      options={useSelectOptions(users, getUserOption)}
      rules={requiredMessage}
      props={{ isDisabled, isLoading: isFetching }}
    />
  );
};
