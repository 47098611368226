import { useQuery } from 'react-query';
import { getChallenge } from './api';

/**
 * @param {string=} challengeId
 * @returns
 */
export const useChallenge = (challengeId) =>
  useQuery(['challenge', challengeId], () => getChallenge(challengeId), {
    enabled: Boolean(challengeId),
  });
