var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { complexityByUserKey, linkedModulesName, moduleIdKey } from '@/admin/challenges/modules/module/const';
import { complexityName } from '@/admin/challenges/modules/complexity/const';
import { Controller, useController } from 'react-hook-form';
export var requiredMessage = { required: 'At least one module is required' };
export var ComplexityLevel = function (_a) {
    var _b;
    var complexity = _a.complexity, module = _a.module, moduleByUser = _a.moduleByUser;
    var field = useController({ name: linkedModulesName }).field;
    var complexityId = +complexity.id;
    var isSelected = ((_b = field.value) === null || _b === void 0 ? void 0 : _b.findIndex(function (selectedModule) {
        return (selectedModule === null || selectedModule === void 0 ? void 0 : selectedModule[moduleIdKey]) === module.id && (selectedModule === null || selectedModule === void 0 ? void 0 : selectedModule[complexityName]) === complexityId;
    })) > -1;
    var isDisabled = function () {
        // disable if already passed by user, but should be available if selected to uncheck
        if (!isSelected && moduleByUser && !moduleByUser[complexityByUserKey].includes(complexityId)) {
            return true;
        }
        return 'editable' in module ? !module.editable : false;
    };
    var handleChange = useCallback(function (evt) {
        var _a;
        var value = (_a = {},
            _a[moduleIdKey] = module.id,
            _a[complexityName] = complexityId,
            _a);
        if (evt.target.checked) {
            field.onChange(__spreadArray(__spreadArray([], __read(field.value), false), [value], false));
        }
        else {
            field.onChange(field.value.filter(function (prevModule) {
                return !(value[moduleIdKey] === prevModule[moduleIdKey] && value[complexityName] === prevModule[complexityName]);
            }));
        }
    }, [module.id, complexityId, field]);
    return (<Controller name={linkedModulesName} rules={requiredMessage} render={function () { return (<Form.Group key={complexityId}>
          <Form.Check disabled={isDisabled()} checked={isSelected} id={"".concat(module.id, "_").concat(complexityId)} onChange={handleChange} label={complexity.title} type="checkbox" value={complexityId} name={module.id}/>
        </Form.Group>); }}/>);
};
