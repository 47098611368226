import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useGrades } from './useGrades';

export const Grades = ({ grades = {}, gradeId, scopeId, onChange }) => {
  const gradeList = useGrades({ grades, scopeId });

  if (!gradeList.length) {
    return null;
  }

  return (
    <Form.Group>
      <Form.Label>Grade</Form.Label>
      <div className="d-flex flex-wrap gap-2">
        {gradeList.map(({ attributes: { name_en: nameEn }, id }) => (
          <Button key={id} value={id} onClick={() => onChange(id)} className="border-1 rounded" active={id === gradeId}>
            {nameEn}
          </Button>
        ))}
      </div>
    </Form.Group>
  );
};
