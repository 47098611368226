import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';

const styles = { menuPortal: (base) => ({ ...base, zIndex: 2 }) };
const getOptionDef = ({ id, name }) => ({ label: name, value: id });

export const useSelectOptions = (list, getOption = getOptionDef) =>
  useMemo(() => list.map(getOption), [getOption, list]);

export const useSelectRender = ({ onChange, options, props }) =>
  useCallback(
    ({ field }) => (
      <Select
        {...field}
        {...props}
        isClearable
        menuPortalTarget={document.body}
        onChange={(option, action) => {
          const value = option?.value ?? null; // pass undefined does not work for clear

          onChange(value);
          field.onChange(value, action);
        }}
        options={options}
        styles={styles}
        value={options.filter((option) => `${option?.value}` === `${field.value}`)}
      />
    ),
    [onChange, options, props],
  );
