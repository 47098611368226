class Api {
  /**
   * Получть JSON.
   * @param {*} response Ответ.
   * @return {*} JSON.
   */
  // eslint-disable-next-line class-methods-use-this
  getJSON(response) {
    return response.json();
  }

  /**
   * Отправить запрос.
   * @param {string} path Адрес.
   * @param {*} options Опции.
   * @return {*} Запрос.
   */
  request(path, options) {
    return fetch(path, options).then(this.getJSON);
  }
}

export const api = new Api();

export const getJSON = (response) => {
  if (!response.ok) {
    return response.json().catch(() => {
      // eslint-disable-next-line no-throw-literal
      throw { errors: [{ detail: { common: [response.statusText] } }] };
    });
  }

  return response;
};

export const checkErrors = (response) => {
  if (response.errors) {
    throw response;
  }

  return response;
};

export const jsonApiRequest = ({ data, options, url }) => {
  const body = JSON.stringify(data);
  return fetch(url, {
    ...options,
    headers: new Headers({ 'content-type': 'application/vnd.api+json' }),
    body,
  })
    .then(getJSON)
    .then(checkErrors);
};

export const CHALLENGES_BASE = '/web_api/v1/challenges';
export const LINKED_MODULES_BASE = '/web_api/v1/linked_modules';
