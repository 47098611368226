var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { expertPrimaryName, expertSecondaryName } from '@/admin/challenges/modules/expert/const';
import { challengeModulesKey } from './const';
export var useFilterModules = function (_a) {
    var experts = _a.experts, gradeId = _a.gradeId, modules = _a.modules, scopeId = _a.scopeId;
    var _b = __read(useWatch({ name: [expertPrimaryName, expertSecondaryName] }), 2), primaryExpertId = _b[0], secondaryExpertId = _b[1];
    var filterByScope = useCallback(function (_a) {
        var scopes = _a.scopes;
        return !scopeId || (scopes === null || scopes === void 0 ? void 0 : scopes.find(function (scope) { return scope.id === scopeId; }));
    }, [scopeId]);
    var filterByGrade = useCallback(function (_a) {
        var grades = _a.grades;
        return !gradeId || (grades === null || grades === void 0 ? void 0 : grades.find(function (grade) { return grade.id === gradeId; }));
    }, [gradeId]);
    var filterByExpert = useCallback(function (_a) {
        var _b, _c, _d, _e;
        var id = _a.id;
        if ((!primaryExpertId && !secondaryExpertId) || !experts) {
            return false;
        }
        var suitsPrimary = !primaryExpertId ||
            Boolean((_c = (_b = experts[primaryExpertId]) === null || _b === void 0 ? void 0 : _b[challengeModulesKey]) === null || _c === void 0 ? void 0 : _c.find(function (expertModule) { return expertModule.id === id; }));
        var suitsSecondary = !secondaryExpertId ||
            Boolean((_e = (_d = experts[secondaryExpertId]) === null || _d === void 0 ? void 0 : _d[challengeModulesKey]) === null || _e === void 0 ? void 0 : _e.find(function (expertModule) { return expertModule.id === id; }));
        return suitsPrimary && suitsSecondary;
    }, [primaryExpertId, secondaryExpertId, experts]);
    return useMemo(function () { return modules === null || modules === void 0 ? void 0 : modules.filter(function (module) { return filterByScope(module) && filterByGrade(module) && filterByExpert(module); }); }, [filterByScope, filterByGrade, filterByExpert, modules]);
};
