import React from 'react';

export const requiredMessage = { required: 'This field is required' };

export const ErrorMessage = ({ error }) => (error ? <div className="text-danger mt-2">{error.message}</div> : null);

/**
 * @param {{
 *  setError: import('react-hook-form').UseFormSetError,
 *  fieldName: string,
 * }} param0
 */
export const handleQueryError =
  ({ setError, fieldName }) =>
  ({ errors }) => {
    errors.forEach((error) => {
      if (error.detail) {
        Object.keys(error.detail).forEach((key) => {
          const [message] = error.detail[key];

          setError(fieldName, { message });
        });
      } else {
        setError(fieldName, { message: error?.message ?? error?.title });
      }
    });
  };
