import { api } from '@/shared/api';

export const getGrade = (gradeId) => api.request(`/web_api/v1/grades/${gradeId}`);
export const getGrades = () => api.request('/web_api/v1/grades');
export const getScopes = () => api.request('/web_api/v1/scopes');
export const getCoreGrades = () => api.request('/web_api/v1/core_grades');
export const getComplexityLevels = () => api.request('/web_api/v1/complexity_levels');
export const getChallengeModules = (scopeId) =>
  api.request(`/web_api/v1/challenge_modules/by_scope?scope_id=${scopeId}`);

export const createGrade = (formData) =>
  api.request('/web_api/v1/grades', {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/vnd.api+json' },
  });

export const updateGrade = ({ id, formData }) =>
  api.request(`/web_api/v1/grades/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/vnd.api+json' },
  });
