var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Grades } from '@/admin/challenges/modules/grade/Grades';
import { Scopes } from '@/admin/challenges/modules/scope/Scopes';
import { useModulesByUser } from '@/admin/challenges/modules/module/useModulesByUser';
import { useController } from 'react-hook-form';
import { useFilterModules } from '@/admin/challenges/modules/module/useFilterModules';
import { ComplexityLevel } from '@/admin/challenges/modules/complexity/ComplexityLevel';
import { useComplexities } from '@/admin/challenges/modules/complexity/useComplexities';
import { useExperts } from '@/admin/challenges/modules/expert/useExperts';
import { useModules } from '@/admin/challenges/modules/module/useModules';
import { complexityKey } from './const';
export var ModulesForm = function () {
    var _a = useExperts().data, _b = _a === void 0 ? {} : _a, experts = _b.data;
    var _c = useModules(), modules = _c.data, isFetching = _c.isFetching;
    var challengerField = useController({ name: 'challenger_id' }).field;
    var modulesByUser = useModulesByUser(+challengerField.value).data;
    var complexities = useComplexities().data;
    var _d = __read(useState(''), 2), scopeId = _d[0], setScopeId = _d[1];
    var _e = __read(useState(''), 2), gradeId = _e[0], setGradeId = _e[1];
    var onScopeChange = useCallback(function (value) {
        setScopeId(value);
        setGradeId('');
    }, []);
    var filteredModules = useFilterModules({
        experts: experts,
        gradeId: gradeId,
        modules: modules === null || modules === void 0 ? void 0 : modules.list,
        scopeId: scopeId,
    });
    if (isFetching) {
        return (<Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>);
    }
    var _f = (modules === null || modules === void 0 ? void 0 : modules.included) || {}, grades = _f.grades, scopes = _f.scopes;
    return (<Container>
      <Row>{scopes && <Scopes onChange={onScopeChange} scopeId={scopeId} scopes={scopes}/>}</Row>
      <Row>{grades && <Grades gradeId={gradeId} grades={grades} onChange={setGradeId} scopeId={scopeId}/>}</Row>
      <fieldset>
        <Form.Group>
          <Row>
            {filteredModules === null || filteredModules === void 0 ? void 0 : filteredModules.map(function (module) { return (<Col key={module.id} md={6}>
                <Form.Label className="font-weight-bold">{module.name}</Form.Label>
                {module[complexityKey].map(function (complexityId) {
                var complexity = complexities === null || complexities === void 0 ? void 0 : complexities.data[complexityId];
                return (complexity && (<ComplexityLevel key={complexityId} complexity={complexity} module={module} moduleByUser={modulesByUser === null || modulesByUser === void 0 ? void 0 : modulesByUser.data[module.id]}/>));
            })}
              </Col>); })}
          </Row>
        </Form.Group>
      </fieldset>
    </Container>);
};
