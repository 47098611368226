import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { challengeName } from './modules/challenge/const';
import { expertPrimaryName } from './modules/expert/const';
document.removeEventListener('DOMContentLoaded', init);
document.addEventListener('DOMContentLoaded', init);
function init() {
    var rootElement = document.getElementById('challenge');
    if (rootElement) {
        var root = ReactDOM.createRoot(rootElement);
        var _a = rootElement.dataset, action = _a.action, _b = challengeName, challengeId = _a[_b], _c = expertPrimaryName, expertPrimaryId = _a[_c];
        if (action) {
            root.render(<App action={action} challengeId={challengeId} expertPrimaryId={expertPrimaryId}/>);
        }
    }
}
