export const getEntityId = ({ id }) => id;

export const reduceRelation = (acc, item) => ({
  ...acc,
  [item.type]: {
    ...(acc[item.type] || {}),
    [item.id]: item,
  },
});

export const reduceRelations = (acc, { data = [] }) => data.reduce(reduceRelation, acc);

export const getEntityItem = ({ attributes = {}, id, relationships = {} }) => ({
  ...attributes,
  ...Object.values(relationships).reduce(reduceRelations, {}),
  id,
});

export const reduceEntity = (acc, item) => ({ ...acc, [getEntityId(item)]: getEntityItem(item) });

export const getEntityData = ({ data = [], included = [] }) => ({
  data: data.reduce(reduceEntity, {}),
  included: included.reduce(reduceRelation, {}),
  list: data.map(getEntityItem),
});
