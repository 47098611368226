import { api, jsonApiRequest, CHALLENGES_BASE } from '@/shared/api';
import { serializeChallenge, transformChallenge } from './transform';

export const createChallenge = ({ data }) =>
  jsonApiRequest({
    data: serializeChallenge(data),
    options: { method: 'POST' },
    url: CHALLENGES_BASE,
  });

export const updateChallenge = ({ challengeId, data }) =>
  jsonApiRequest({
    data: serializeChallenge(data),
    options: { method: 'PATCH' },
    url: `${CHALLENGES_BASE}/${challengeId}`,
  });

export const getChallenge = async (challengeId) =>
  transformChallenge(await api.request(`${CHALLENGES_BASE}/${challengeId}`));
