import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getGrades } from '@/admin/grades/api';

export const gradeShared = {
  id: '',
  type: 'grades',
  attributes: { name_en: 'All' },
  relationships: { scope: { data: { id: 1 } } },
};

export const useGrades = ({ scopeId }) => {
  const { data: { data: grades = [] } = {} } = useQuery('grades', getGrades);
  const useGradeFilter = useCallback((grade) => grade.relationships.scope.data.id === scopeId, [scopeId]);

  return useMemo(() => [gradeShared, ...Object.values(grades).filter(useGradeFilter)], [grades, useGradeFilter]);
};
