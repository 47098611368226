import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@/shared/ErrorMessage';
import { useSelectRender } from './useSelectRender';

export const Select = ({ control, errors = {}, label, name, onChange, options, props, rules }) => (
  <Form.Group>
    <Form.Label>{label}</Form.Label>
    <Controller control={control} name={name} render={useSelectRender({ onChange, options, props })} rules={rules} />
    <ErrorMessage error={errors[name]} />
  </Form.Group>
);
