import React, { useCallback, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ErrorMessage, handleQueryError, requiredMessage } from '@/shared/ErrorMessage';
import { Challengers } from '@/admin/challenges/modules/challenger/Challengers';
import { Experts } from '@/admin/challenges/modules/expert/Experts';
import { linkedModulesName } from '@/admin/challenges/modules/module/const';
import { deleteModule } from '@/admin/challenges/modules/module/api';
import { ModulesForm } from '@/admin/challenges/modules/module/ModulesForm';
import { createChallenge, updateChallenge } from './api';
import { ServerAlertError } from './ServerError';

/**
 * @param {{
 *  action: string,
 *  challenge: import('./transform').ChallengeTransformed,
 *  challengeId?: string,
 *  expertPrimaryId?: string,
 * }} param0
 */
export const ChallengeForm = ({ action, challenge, challengeId, expertPrimaryId }) => {
  const form = useForm({
    defaultValues: challenge || {
      primary_expert_id: expertPrimaryId,
      linked_modules: [],
      date: '',
    },
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = form;

  const setFormErrors = useCallback(
    ({ errors: mutErrors }) => {
      mutErrors.forEach(({ detail }) => {
        Object.keys(detail).forEach((key) => {
          const [message] = detail[key];

          setError(`${key}`, { message });
        });
      });
    },
    [setError],
  );

  const {
    mutateAsync: createMutate,
    isLoading: isCreateLoading,
    isSuccess: createSucceed,
  } = useMutation(createChallenge);

  const {
    mutateAsync: updateMutate,
    isLoading: isUpdateLoading,
    isSuccess: updateSucceed,
  } = useMutation(updateChallenge);

  const { mutateAsync: deleteLinkedModule, isSuccess: deleteSucceed, isIdle } = useMutation(deleteModule);

  const isMutateLoading = isCreateLoading || isUpdateLoading;
  const isMutateSucceed = (createSucceed || updateSucceed) && (deleteSucceed || isIdle);
  const isDisabled = (challenge && !challenge.editable) || isMutateLoading;
  const isSubmitDisabled = isDisabled || isMutateLoading;

  useEffect(() => {
    if (isMutateSucceed) {
      window.location.href = action;
    }
  }, [action, isMutateSucceed]);

  const onSubmit = useCallback(
    async (data) => {
      if (challengeId) {
        const addedModules = data.linked_modules.filter((challengeModule) => !challengeModule.id);
        await updateMutate({ challengeId, data: { ...data, linked_modules: addedModules } }).catch(setFormErrors);

        for (const challengeModule of challenge.linked_modules) {
          if (data.linked_modules.findIndex((formModule) => formModule.id === challengeModule.id) === -1) {
            // should do request sequence to avoid race condition
            // eslint-disable-next-line no-await-in-loop
            await deleteLinkedModule(challengeModule.id).catch(
              handleQueryError({ setError, fieldName: linkedModulesName }),
            );
          }
        }
      } else {
        await createMutate({ data }).catch(setFormErrors);
      }
    },
    [challenge?.linked_modules, challengeId, createMutate, deleteLinkedModule, setError, setFormErrors, updateMutate],
  );

  return (
    <FormProvider {...form}>
      <Row className="mb-4">
        <Col>
          <Button variant="outline-secondary" type="button" className="mr-3" onClick={() => window.history.back()}>
            Back
          </Button>
        </Col>
      </Row>
      <ServerAlertError errors={errors} errorKey="common" clearErrors={clearErrors} />
      <ServerAlertError errors={errors} errorKey="base" clearErrors={clearErrors} />
      <form action={action} method="post" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <Controller
                name="date"
                rules={requiredMessage}
                render={({ field }) => <Form.Control {...field} type="date" disabled={isDisabled} />}
              />
              <ErrorMessage error={errors.date} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Challengers isDisabled={isDisabled} />
          </Col>
        </Row>
        <Experts isDisabled={isDisabled} control={control} errors={errors} />
        <h4>Modules</h4>
        <ServerAlertError errors={errors} errorKey={linkedModulesName} clearErrors={clearErrors} />
        <ModulesForm />
        <Row>
          <Col className="text-right">
            <Button variant="primary" type="submit" disabled={isSubmitDisabled}>
              {challengeId ? 'Update challenge' : 'Create challenge'}
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};
