import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { requiredMessage } from '@/shared/ErrorMessage';
import { Select } from '@/admin/challenges/modules/form/Select';
import { useSelectOptions } from '@/admin/challenges/modules/form/useSelectRender';
import { useExperts } from '@/admin/challenges/modules/expert/useExperts';
import { expertPrimaryName, expertSecondaryName } from './const';
import { getExpertOption } from './utils';

export const Experts = ({ control, errors, isDisabled }) => {
  const { data: { list: expertList = [] } = {}, isLoading } = useExperts();
  const options = useSelectOptions(expertList, getExpertOption);
  const { setValue } = useFormContext();

  const expertPrimaryValue = useWatch({ control, name: expertPrimaryName });
  const optionsSecondary = useMemo(
    () => options.filter(({ value }) => `${value}` !== `${expertPrimaryValue}`),
    [expertPrimaryValue, options],
  );

  const onPrimaryChange = useCallback(
    (value) => {
      setValue(expertPrimaryName, value);
      setValue(expertSecondaryName, null);
    },
    [setValue],
  );

  const onSecondaryChange = useCallback(
    (value) => {
      setValue(expertSecondaryName, value);
    },
    [setValue],
  );

  return (
    <Row>
      <Col md={6}>
        <Select
          control={control}
          errors={errors}
          label="Primary expert"
          name={expertPrimaryName}
          onChange={onPrimaryChange}
          options={options}
          props={{ isDisabled, isLoading }}
          rules={requiredMessage}
        />
      </Col>
      <Col md={6}>
        <Select
          control={control}
          errors={errors}
          label="Secondary expert (Optional)"
          name={expertSecondaryName}
          onChange={onSecondaryChange}
          options={optionsSecondary}
          props={{ isDisabled: isDisabled || !expertPrimaryValue, isLoading }}
        />
      </Col>
    </Row>
  );
};
