import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ChallengeForm } from './ChallengeForm';
import { useChallenge } from './useChallenge';

export const Challenge = ({ action, challengeId, expertPrimaryId }) => {
  const { data: challenge, isFetching } = useChallenge(challengeId);

  if (isFetching) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  return (
    <ChallengeForm action={action} challenge={challenge} challengeId={challengeId} expertPrimaryId={expertPrimaryId} />
  );
};
