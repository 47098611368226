import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useScopes } from './useScopes';

export const Scopes = ({ scopes = {}, scopeId, onChange }) => (
  <Form.Group>
    <Form.Label>Scope</Form.Label>
    <div className="d-flex flex-wrap gap-2">
      {useScopes(scopes).map(({ name, id }) => (
        <Button key={id} value={id} onClick={() => onChange(id)} className="border-1 rounded" active={id === scopeId}>
          {name}
        </Button>
      ))}
    </div>
  </Form.Group>
);
