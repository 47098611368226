var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { reduceRelation } from '@/shared/utils';
export var getEntityItem = function (_a) {
    var attributes = _a.attributes, id = _a.id, type = _a.type, relationships = _a.relationships;
    var base = __assign({ id: id, type: type }, attributes);
    if (!relationships) {
        return base;
    }
    return __assign(__assign({}, base), Object.entries(relationships).reduce(reduceRelation, {}));
};
var isGrade = function (item) { return item.type === 'grades'; };
var isScope = function (item) { return item.type === 'scopes'; };
export var getEntityData = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.included, included = _c === void 0 ? [] : _c;
    var formattedData = {};
    var list = [];
    data.forEach(function (item) {
        var formatted = getEntityItem(item);
        formattedData[item.id] = formatted;
        list.push(formatted);
    });
    return {
        data: formattedData,
        included: included.reduce(function (acc, _a) {
            var attributes = _a.attributes, id = _a.id, type = _a.type, relationships = _a.relationships;
            var record = __assign(__assign({ id: id, type: type }, attributes), Object.entries(relationships || {}).reduce(reduceRelation, {}));
            if (isGrade(record) || isScope(record)) {
                acc[type][id] = record;
            }
            return acc;
        }, { scopes: {}, grades: {} }),
        list: list,
    };
};
export var getEntity = function (_a) {
    var attributes = _a.attributes, id = _a.id, type = _a.type, relationships = _a.relationships;
    var base = __assign({ id: id, type: type }, attributes);
    if (!relationships) {
        return base;
    }
    return __assign(__assign({}, base), Object.entries(relationships).reduce(reduceRelation, {}));
};
export var getCompletedModulesData = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b;
    var formattedData = {};
    var list = [];
    data.forEach(function (item) {
        var formatted = getEntity(item);
        formattedData[item.id] = formatted;
        list.push(formatted);
    });
    return {
        data: formattedData,
        list: list,
    };
};
