import { complexityName } from '@/admin/challenges/modules/complexity/const';
import { linkedModulesName, moduleIdKey } from '@/admin/challenges/modules/module/const';

/**
 * @typedef {{
 *   id: string,
 *   type: "challenges",
 * }} ChallengesRelation
 *
 * @typedef {{
 *   id: string,
 *   type: "linked_modules",
 * }} LinkedModulesRelation
 *
 * @typedef {{
 *   id: string,
 *   type: "challenge_modules",
 * }} ChallengeModulesRelation
 *
 * @typedef {{
 *   id: string,
 *   type: "complexity_levels",
 * }} ComplexityLevelsRelation
 *
 * @typedef {{
 *   attributes: {
 *      challenger_id: number,
 *      date: string,
 *      editable: boolean,
 *      primary_expert_id: number,
 *      secondary_expert_id: number,
 *   },
 *   id: string,
 *   relationships: {
 *     challenge_modules: {
 *       data: ChallengeModulesRelation[],
 *     },
 *     linked_modules: {
 *       data: LinkedModulesRelation[],
 *     },
 *   },
 *   type: "challenges",
 * }} Challenges
 *
 * @typedef {{
 *   attributes: {
 *     editable: boolean,
 *   },
 *   id: string,
 *   relationships: {
 *     challenge: {
 *       data: ChallengesRelation,
 *     },
 *     challenge_module: {
 *       data: ChallengeModulesRelation,
 *     },
 *     complexity_level: {
 *       data: ComplexityLevelsRelation,
 *     },
 *   },
 *   type: "linked_modules",
 * }} LinkedModules
 *
 * @typedef {{
 *   attributes: {
 *     complexity_level_ids: number[],
 *     description: string,
 *     name: string,
 *   },
 *   id: string,
 *   relationships: {
 *     grades: {
 *       meta: { included: false },
 *     },
 *     scopes: {
 *       meta: { included: false },
 *     },
 *   },
 *   type: "challenge_modules",
 * }} ChallengeModules
 *
 * @typedef {{
 *   attributes: {
 *     name: string,
 *     priority: number,
 *     title: string,
 *   },
 *   id: string,
 *   type: "complexity_levels",
 * }} ComplexityLevels
 */

/**
 * @typedef {{
 *   id?: string,
 *   editable: boolean,
 *   challenge_module_id: string,
 *   complexity_level_id: number,
 * }} LinkedModule
 *
 * @typedef {{
 *  id: Challenges['id']
 *  linked_modules: LinkedModule[]
 * } & Challenges['attributes']} ChallengeTransformed
 */

/**
 * transform challenge from JSON API response format into simple object for usage in form data
 * @param {{
 *  data: Challenges,
 *  included: Array<LinkedModules | ChallengeModules | ComplexityLevels>
 * }} challengeResponse
 * @returns {ChallengeTransformed}
 */
export function transformChallenge({ data, included }) {
  /** @type {{ challengeModules: Record<string, LinkedModules>, complexityLevels: Record<string, ComplexityLevels>, linkedModules: Record<string, LinkedModules> }} */
  const { linkedModules, challengeModules, complexityLevels } = included.reduce(
    (acc, lm) => {
      switch (lm.type) {
        case 'challenge_modules':
          acc.challengeModules[lm.id] = lm;
          break;
        case 'complexity_levels':
          acc.complexityLevels[lm.id] = lm;
          break;
        case 'linked_modules':
          acc.linkedModules[lm.id] = lm;
          break;
        default:
      }
      return acc;
    },
    { challengeModules: {}, complexityLevels: {}, linkedModules: {} },
  );
  return {
    id: data.id,
    ...data.attributes,
    [linkedModulesName]: Object.values(linkedModules).map((lm) => {
      const challengeModule = challengeModules[lm.relationships.challenge_module.data.id];
      const complexityLevel = complexityLevels[lm.relationships.complexity_level.data.id];
      return {
        id: lm.id,
        editable: lm.attributes.editable,
        [moduleIdKey]: challengeModule ? challengeModule.id : null,
        [complexityName]: complexityLevel ? Number(complexityLevel.id) : null,
      };
    }),
  };
}

/**
 * @param {ChallengeTransformed} challenge
 * @returns {{
 *  data: {
 *    id: Challenges['id'],
 *    type: 'challenges',
 *    attributes: {
 *     date: Challenges['attributes']['date'],
 *     primary_expert_id: Challenges['attributes']['primary_expert_id'],
 *     secondary_expert_id: Challenges['attributes']['secondary_expert_id'],
 *     linked_modules: Array<{
 *      challenge_module_id: LinkedModule['challenge_module_id'],
 *      complexity_level_id: LinkedModule['complexity_level_id'],
 *     }>,
 *    }
 *  }
 * }}
 */
export function serializeChallenge(challenge) {
  return {
    data: {
      id: challenge.id,
      type: 'challenges',
      attributes: {
        date: challenge.date,
        challenger_id: challenge.challenger_id,
        primary_expert_id: challenge.primary_expert_id,
        secondary_expert_id: challenge.secondary_expert_id,
        linked_modules:
          challenge.linked_modules.map((lm) => ({
            challenge_module_id: lm.challenge_module_id,
            complexity_level_id: lm.complexity_level_id,
          })) || [],
      },
    },
  };
}
