export function secondsToMMSS(str) {
  const time = parseInt(str, 10);
  let result = '';

  const minutes = Math.trunc(time / 60);
  if (minutes < 10) {
    result += '0';
  }
  result += `${minutes}:`;

  const seconds = time % 60;
  if (seconds < 10) {
    result += '0';
  }
  result += seconds;
  return result;
}
