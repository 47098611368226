import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Challenge } from './modules/challenge/Challenge';

const TEN_SECONDS = 10000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: TEN_SECONDS,
    },
  },
});

export const App = ({ action, challengeId, expertPrimaryId }) => (
  <QueryClientProvider client={queryClient}>
    <Challenge action={action} challengeId={challengeId} expertPrimaryId={expertPrimaryId} />
  </QueryClientProvider>
);
